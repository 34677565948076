// breakpoints
$breakpoint-xs: 375px;
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1100px;
$breakpoint-xl: 1920px;

$breakpoint-height-xs: 570px;

// raw colors
$swift-blue: #0677D1;
$dark-gray: #4F4D47;
$abbey-gray: #47525A;
$light-gray-blue: #d6dbde;
$neon-green: #8ABF2E;
$neon-dark-green: #649908;
$light-blue: #EDF7FE;
$blue-gray: #EDF7FE;
$profile-border-blue: #ddebf7;
$soft-blue: #C2EDF9;
$malibu-blue: #5BBBEB;
$dark-blue: #00529E;
$button-focus-blue: #05599D;
$very-dark-blue: #22324E;
$orange: #FDA213;

$white: #FFFFFF;

$zebra-stripe: #F9F9F9;
$light-gray:#F5F6F7;
$gray: #EDF7FE;
$gray-card-stroke: #DBDBDA;
$slate: #BEC3C6;
$cloudy: #96989A;
$disabled-gray: #98A4AE;
$tapa: #686C6D;
$heavy-metal: #2D302B;

$bg-error-light: #F6D5D5;
$bg-warning-light: #FEF3C2;
$bg-gray-light: #EAEDEF;
$bg-app-gray: #E5E5E5;

$error-red: #D32F2F;
$warning-yellow: #FDD835;
$button-blue: #007dd7;

// semantic colors
$text-color: $abbey-gray;
$primary-blue: $swift-blue;

// gradients
$gradient-light-blue: linear-gradient(90deg, #5BBBEB 2.04%, #007DD7 98.66%);
$gradient-dark-blue: linear-gradient(90deg, #007DD7 2.04%, #035BAB 98.66%);
$gradient-green-dark-blue: linear-gradient(90deg, #D1DD4A 2.04%, #5BBBEB 98.66%);

// font sizes
$font-xs: 9px;
$font-sm: 12px;
$font-normal: 16px;
$font-heading-sm: 20px;
$font-heading-md: 25px;
$font-heading-lg: 32px;
$font-heading-xl: 41px;
$font-heading-xxl: 52px;

// Scaffolding sizes
$header-height-mobile: 56px;
$header-height: 70px;
