/* You can add global styles to this file, and also import other style files */
@import "./styles/global.scss";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// Needs to be scoped outside of the :host due to the "portaling" of the panel to the top of the DOM
.mat-select-panel.state-selector-panel {
  width: 76px;
  max-width: 76px;
  // Causes a flash of wider content. Need to override better
  min-width: auto !important;
}

.mat-select-panel.date-day-selector-panel {
  width: 89px;
  max-width: 89px;
  // Causes a flash of wider content. Need to override better
  min-width: auto !important;
}

.mat-select-panel.other-beneficiaries-panel {
  transform: translateX(23px) !important;
  width: calc(100% + 32px) !important;
  max-width: calc(100% + 32px) !important;
  min-width: calc(100% + 32px) !important;
}
