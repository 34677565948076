@import 'src/styles/common';

.tippy-box[data-theme~='gray-theme'] {
  background-color: $abbey-gray;
  max-width: 285px !important;
  color: $white;
  align-self: stretch;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  padding: 15px;
  font-size: $font-normal;
  position: relative;
}

// this may not be the best place to put this globally, but... it's only for the tippies!!
.help-icon {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 19px;
  left: 5px;
  top: 3px;
}

@media only screen and (min-width: $breakpoint-sm) {
  .tippy-box[data-theme~='gray-theme'] {
    max-width: 343px !important;
  }
}