@import "../variables";

@mixin styled-input() {
  background: $white;
  border: 1px solid $gray-card-stroke;
  border-radius: 8px;
  color: $text-color;
  font-size: 16px;
  line-height: 54px;
  padding: 0 16px;
  position: relative;
  width: 100%;
  outline: none;

  &:focus {
    border: 1px solid $swift-blue;
  }


  &::placeholder {
    color: $dark-gray;
    font-size: 16px;
    line-height: 21px;
  }

  &.has-error,
  &.ng-invalid.ng-touched,
  &.ng-invalid.ng-touched input {
    background: rgba(211, 47, 47, 0.1);
    border: 1px solid $error-red;
  }

  &:disabled {
    background: $gray-card-stroke;
  }
}

@mixin styled-label() {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: $dark-gray;
}

@mixin styled-radio() {
  list-style: none;
  text-align: left;
  padding: 16px;
  border: 1px solid $gray-card-stroke;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 8px;


  label {
    position: relative;
  }

  input[type="radio"] {
    width: 50px;
    opacity: 0;
    position: absolute;
  }

  .custom-checkbox {
    height: 16px;
    width: 16px;
    margin: 0 16px;
    background: $zebra-stripe;

    border: 1px solid #96989A;
    box-sizing: border-box;
    border-radius: 16px;
  }

  input:checked ~ .custom-checkbox {
    border: 5px solid $primary-blue;
    box-sizing: border-box;
    border-radius: 16px;

  }

  // override focus
  input:focus ~ .custom-checkbox {
    outline: -webkit-focus-ring-color auto 5px;
  }
}
