@import './common';

.icon {
  height: 21px;
  width: 21px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-cc {
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.0844 0.789459H2.91595C1.79427 0.789459 0.905003 1.70288 0.905003 2.84209L0.894897 15.1579C0.894897 16.2971 1.79427 17.2105 2.91595 17.2105H19.0844C20.2061 17.2105 21.1054 16.2971 21.1054 15.1579V2.84209C21.1054 1.70288 20.2061 0.789459 19.0844 0.789459ZM19.0844 15.1579H2.91595V8.99999H19.0844V15.1579ZM19.0844 4.89472H2.91595V2.84209H19.0844V4.89472Z' fill='%23686C6D'/%3E%3C/svg%3E%0A");

  &.blue {
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Csvg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.0845 0.789459H2.91607C1.79439 0.789459 0.905125 1.70288 0.905125 2.84209L0.89502 15.1579C0.89502 16.2971 1.79439 17.2105 2.91607 17.2105H19.0845C20.2062 17.2105 21.1055 16.2971 21.1055 15.1579V2.84209C21.1055 1.70288 20.2062 0.789459 19.0845 0.789459ZM19.0845 15.1579H2.91607V8.99999H19.0845V15.1579ZM19.0845 4.89472H2.91607V2.84209H19.0845V4.89472Z' fill='%230677D1'/%3E%3C/svg%3E%3C/svg%3E%0A");
  }
}


.icon-bank {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 9H2V16H5V9Z' fill='%23686C6D'/%3E%3Cpath d='M11.5 9H8.5V16H11.5V9Z' fill='%23686C6D'/%3E%3Cpath d='M20 18H0V21H20V18Z' fill='%23686C6D'/%3E%3Cpath d='M18 9H15V16H18V9Z' fill='%23686C6D'/%3E%3Cpath d='M10 0L0 5V7H20V5L10 0Z' fill='%23686C6D'/%3E%3C/svg%3E%0A");

  &.blue {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 9H2V16H5V9Z' fill='%230677D1'/%3E%3Cpath d='M11.5 9H8.5V16H11.5V9Z' fill='%230677D1'/%3E%3Cpath d='M20 18H0V21H20V18Z' fill='%230677D1'/%3E%3Cpath d='M18 9H15V16H18V9Z' fill='%230677D1'/%3E%3Cpath d='M10 0L0 5V7H20V5L10 0Z' fill='%230677D1'/%3E%3C/svg%3E%0A");
  }
}

