@import "../variables";


// Card widths
$narrow-card-page-width: 425px;
$medium-card-page-width: 625px;
$wide-card-page-width: 676px;

@mixin card() {
  @include shadow-1();
  background: $white;
  border-radius: 6px;
  padding: 16px;

  @media(min-width: $breakpoint-md) {
    border-radius: 8px;
  }
}

// This is used for Cards that are their own whole page.
// This handles some responsiveness concerns more than just the styling.
@mixin card-page() {
  @include shadow-1();
  background: $white;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 16px;
  margin: 0 auto;
  width: 100vw;
  min-height: 100vh;

  @media(min-width: $breakpoint-sm) {
    width: auto;
    min-height: auto;
    height: auto;
    max-width: 480px;
    padding: 64px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin-top: 24px;
  }
}

@mixin shadowless-card-page($max-width) {
  @include card-page();

  @media(min-width: $breakpoint-sm) {
    max-width: $max-width;
    padding: 16px;
  }

  h2 {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    padding-bottom: 8px;
    margin-bottom: 18px;
  }
}

@mixin card-title() {
  margin: 0 -8px 16px -8px;
  padding: 0 8px 8px 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
}
