@import "src/styles/common";
@import "src/assets/fonts/stylesheet";
@import "src/styles/icons";
@import "src/styles/pages";
@import "src/styles/globals/inputs";
@import "src/styles/globals/select";
@import "src/styles/globals/dialog";
@import "src/styles/globals/quill";
@import "src/styles/globals/tippy";

* {
  font-family: Assistant, sans-serif;
  color: $text-color;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: $light-gray;

  
}
/** 
  This is used to update the size of the dialog container on mobile
  for the getting-started page when you reject the offer.
  Without it, dialog will stay a large size for mobile devices
 */
mat-dialog-container {
  @media(max-width: $breakpoint-sm) {
    width: auto !important;
    height: auto !important;
  }
}

// Standard Elements

img {
  max-width: 100%;
  height: auto;
}

input, textarea {
  @include styled-input();
}

input::placeholder, textarea::placeholder {
  color: $cloudy;
}

textarea::placeholder {
  padding-top: 16px;
}

.field-wrapper {
  label {
    @include styled-label();
  }
}


// Utility Classes
.page-container {
  margin: 0 auto;
  width: 100%;
  max-width: $breakpoint-sm;
  padding: 0 16px;

  @media(min-width: $breakpoint-sm) {
    max-width: $breakpoint-sm;
  }
  @media(min-width: $breakpoint-md) {
    max-width: $breakpoint-md;
  }
  @media(min-width: $breakpoint-lg) {
    max-width: $breakpoint-lg;
  }
  // @media(min-width: $breakpoint-xl) {
  //   max-width: $breakpoint-xl;
  // }
}

.full-page-container {
  max-width: 100vw;
  // min-height: calc(100vh - $header-height);

  @media screen and (max-width: $breakpoint-sm) {
    min-height: calc(100vh - $header-height-mobile);
  }
}

.text-button {
  @include text-button();
}

.link-button {
  @include link-button();
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

sup {
  color: inherit;
}

.card-title {
  @include card-title();
  margin-bottom: 16px;
}
