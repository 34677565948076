@import "variables";

@import "./mixins/buttons";
@import "./mixins/inputs";
@import "./mixins/cards";
@import "./mixins/layout";

//
// Shadows
//
@mixin shadow-1 {
  box-shadow: 0px 4px 16px rgba(126, 133, 142, 0.2);
}
@mixin shadow-2 {
  box-shadow: 0px 12px 16px rgba(23, 31, 70, 0.12);
}
@mixin shadow-3 {
  box-shadow: 0px 16px 38px rgba(23, 31, 70, 0.22);
}

//
// Custom Shadows
//
@mixin shadow-page {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
}

//
// Misc
//

@mixin sr-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}
