@import 'src/styles/common';

.bank-account-number-input {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }
}

// Stripe Element Styling

.stripe-wrapper {
  @include styled-input();

  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;


  .StripeElement {
    width: 100%;
    font-size: 16px;
  }
}

.card-errors {
  display: flex;
  align-items: center;
}

