@import "../variables";

@mixin button() {
  background: $primary-blue;
  border: 1px solid $primary-blue;
  border-radius: 8px;
  width: 100%;
  line-height: 54px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

@mixin elevated-button() {
  @include button();

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

@mixin secondary-button() {
  @include elevated-button();
  background: $white;
  border: 1px solid $white;
}

@mixin primary-button() {
  @include elevated-button();
  color: $white;
  background: $primary-blue;
  border: 1px solid $primary-blue;
}

@mixin error-button() {
  @include elevated-button();
  color: $white;
  background: $error-red;
  border: 1px solid $error-red;
}

@mixin card-button() {
  @include button();

  border: 1px solid #DBDBDA;
  background: $white;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: $primary-blue;
  padding: 8px 16px;

  &:hover {
    background: $swift-blue;
    color: $white;
  }

  &:focus {
    background: $button-focus-blue;
    color: $white;
  }
}

@mixin menu-button() {
  @include button();

  justify-content: flex-start;
  padding: 12px 24px;
  background: $white;
  border: 0;
  border-radius: 0;
  line-height: 24px;
  font-weight: normal;

  &:hover {
    color: $white;
    background: $swift-blue
  }
}

@mixin circle-button() {
  @include button();

  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: $white;
  font-size: $font-heading-sm;
  font-weight: normal;
}

@mixin text-button() {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  appearance: none;
  cursor: pointer;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

@mixin link-button() {
  @include text-button();

  color: $swift-blue;
  text-decoration: underline;
}
