@font-face {
    font-family: 'Assistant';
    src: url('^assets/fonts/Assistant-ExtraLight.eot');
        src: url('^assets/fonts/Assistant-ExtraLight.eot?#iefix') format('embedded-opentype'),
            url('^assets/fonts/Assistant-ExtraLight.woff2') format('woff2'),
            url('^assets/fonts/Assistant-ExtraLight.woff') format('woff'),
            url('^assets/fonts/Assistant-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('^assets/fonts/Assistant-SemiBold.eot');
        src: url('^assets/fonts/Assistant-SemiBold.eot?#iefix') format('embedded-opentype'),
            url('^assets/fonts/Assistant-SemiBold.woff2') format('woff2'),
            url('^assets/fonts/Assistant-SemiBold.woff') format('woff'),
            url('^assets/fonts/Assistant-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('^assets/fonts/Assistant-ExtraBold.eot');
        src: url('^assets/fonts/Assistant-ExtraBold.eot?#iefix') format('embedded-opentype'),
            url('^assets/fonts/Assistant-ExtraBold.woff2') format('woff2'),
            url('^assets/fonts/Assistant-ExtraBold.woff') format('woff'),
            url('^assets/fonts/Assistant-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('^assets/fonts/Assistant-Bold.eot');
        src: url('^assets/fonts/Assistant-Bold.eot?#iefix') format('embedded-opentype'),
            url('^assets/fonts/Assistant-Bold.woff2') format('woff2'),
            url('^assets/fonts/Assistant-Bold.woff') format('woff'),
            url('^assets/fonts/Assistant-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('^assets/fonts/Assistant-Regular.eot');
        src: url('^assets/fonts/Assistant-Regular.eot?#iefix') format('embedded-opentype'),
            url('^assets/fonts/Assistant-Regular.woff2') format('woff2'),
            url('^assets/fonts/Assistant-Regular.woff') format('woff'),
            url('^assets/fonts/Assistant-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('^assets/fonts/Assistant-Light.eot');
        src: url('^assets/fonts/Assistant-Light.eot?#iefix') format('embedded-opentype'),
            url('^assets/fonts/Assistant-Light.woff2') format('woff2'),
            url('^assets/fonts/Assistant-Light.woff') format('woff'),
            url('^assets/fonts/Assistant-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

